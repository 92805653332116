import {arrow, computePosition} from "@floating-ui/dom";
import Accordion from "./modules/accordion";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
  const infoButton = document.querySelectorAll('.info-button');

  function handleDOMContentLoaded() {
    new Accordion();

    infoButton.forEach((el) => {
      const tooltip = el.nextElementSibling;
      const tooltipArrow = tooltip.firstElementChild;

      computePosition(el, tooltip, {
        placement: 'left',
        middleware: [
          arrow({ element: tooltipArrow })
        ]
      })
        .then(({ x, y, placement, middlewareData }) => {
          Object.assign(tooltip.style, {
            left: `${x}px`,
            top: `${y}px`
          });

          const { x: arrowX, y: arrowY } = middlewareData.arrow;

          const staticSide = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right',
          }[placement.split('-')[0]];

          Object.assign(tooltipArrow.style, {
            left: arrowX != null ? `${arrowX}px` : '',
            top: arrowY != null ? `${arrowY}px` : '',
            right: '',
            bottom: '',
            [staticSide]: '-5px',
          });
        });

      el.addEventListener('mouseenter', () => tooltip.classList.add('tooltip--visible'));
      el.addEventListener('mouseleave', () => tooltip.classList.remove('tooltip--visible'));
    });
  }
})();
